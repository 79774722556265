const track = (title, path) => {
  window.analytics.track("CTA:Clicked", {
    value: title,
    label: path,
  })
}
export default track

export const trackV2 = (action, { category = "Click", label }) => {
  window.analytics.track(action, {
    category,
    label,
  })
}
